//
// Utilities
//

@import "../core/utilities/backgrounds";
@import "../core/utilities/floating";
@import "../core/utilities/helper";
@import "../core/utilities/image";
@import "../core/utilities/opacity";
@import "../core/utilities/overflow";
@import "../core/utilities/position";
@import "../core/utilities/sizing";
@import "../core/utilities/spacing";
@import "../core/utilities/shadows";
@import "../core/utilities/text";
@import "../core/utilities/transform";

.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
