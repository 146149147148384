//
// MISCELLANEOUS
//

/* Fix dropdown-menu position in TopMenu */
.misc--dropdown-menu {
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
}

/* Fix bg-default */
.misc--bg-default {
  &::after {
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background: $default;
    z-index: -1;
  }
}

.cursor-pointer {
  cursor: pointer;
}
